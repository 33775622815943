import Vue from "vue";
import axios from "@/common/interceptors.service";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);
export default {
  getUrl(url, params, config) {
    return Vue.axios.post(url, params, config).catch(error => {
      throw error.response;
    });
  }
};
