<template>
  <div>
    <div class="balance-card" v-if="trades.length > 0">
      <div>
        <div class="bb-intro-top">
          <i class="fas fa-donate"></i>
          <b class="b-icon-title"> {{ $t("sat.Deposit More Coins") }}</b>
        </div>
        <div class="b-info">
          <p class="info-tip">{{ $t("toefl.PaymentMethod") }}</p>
          <el-select v-model="paymentMethod" style="width:100%">
            <el-option
              v-for="payments in paymentMethods"
              :key="payments.value"
              :label="payments.label"
              :value="payments.value"
            >
            </el-option>
          </el-select>
          <template v-if="paymentMethod === 'STRIPE'">
            <el-alert
              style="margin-top:15px"
              type="warning"
              :closable="false"
              show-icon
            >
              <template slot="title">
                <label v-html="$t('toefl.HandlingFee')"></label>
              </template>
            </el-alert>
            <p class="info-tip">{{ $t("toefl.Amount") }}</p>
            <div class="trade-list-wrapper">
              <div class="row">
                <template v-if="false">
                  <div
                    class="col-sm-6 col-md-6"
                    v-for="(plan, index) in usdPlans"
                    :key="index"
                  >
                    <div
                      :class="
                        plan.selected
                          ? 'trade-wrapper selected'
                          : 'trade-wrapper'
                      "
                      @click="selectPlan(index)"
                    >
                      <el-tag
                        class="tags"
                        type="warning"
                        effect="dark"
                        v-show="
                          Number(plan.price) / 1.05 / Number(plan.coin) < 1
                        "
                      >
                        {{
                          $t("sat.discount", {
                            num:
                              lang === "en"
                                ? 100 -
                                  (Number(plan.price) /
                                    1.05 /
                                    Number(plan.coin)) *
                                    100
                                : (Number(plan.price) /
                                    1.05 /
                                    Number(plan.coin)) *
                                  10
                          })
                        }}
                      </el-tag>
                      <div class="text-center">
                        <b class="text-success tcoin">{{ plan.coin }}</b>
                        <b class="bb-name">{{ $t("sat.Coins") }}</b
                        ><br />
                        <small style="font-size:12px">
                          =

                          <span v-if="Number(plan.coin) > 10">
                            {{ Number(plan.coin / 10) }}
                            {{
                              Number(plan.coin / 10) > 1
                                ? "Full Tests"
                                : "Full Test"
                            }}
                            /
                          </span>
                          {{ parseInt(plan.coin / 4) }}
                          {{
                            Number(plan.coin / 10) > 1 ? "Modules" : "Module"
                          }}
                          / {{ plan.coin / 1 }} Drills
                        </small>
                        <hr />
                        <div style="font-size:18px">
                          <b>
                            ${{ showUSD(Number(plan.price) / 1.05) }}
                            {{ plan.currency.toUpperCase() }}
                            <el-tooltip
                              class="item"
                              effect="dark"
                              content="Processing fee"
                              placement="top"
                            >
                              <el-tag
                                class="stripe-tag"
                                type="warning"
                                size="mini"
                                effect="dark"
                              >
                                +5%
                              </el-tag>
                            </el-tooltip>
                          </b>
                        </div>
                        <div
                          v-show="
                            parseInt(plan.price) / parseInt(plan.coin) < 1
                          "
                        >
                          <small class="line">
                            ${{ Math.round(plan.coin) }}
                            {{ plan.currency.toUpperCase() }}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template>
                  <div
                    class="col-sm-6 col-md-6"
                    v-for="(plan, index) in usdPlans"
                    :key="index"
                  >
                    <div
                      :class="
                        plan.selected
                          ? 'trade-wrapper selected'
                          : 'trade-wrapper'
                      "
                      @click="selectPlan(index)"
                    >
                      <div class="text-center">
                        <b class="text-success tcoin">{{ plan.coin }}</b>
                        <b class="bb-name">{{ $t("sat.Coins") }}</b
                        ><br />
                        <small style="font-size:12px">
                          =

                          <span v-if="Number(plan.coin) > 10">
                            {{ Number(plan.coin / 10) }}
                            {{
                              Number(plan.coin / 10) > 1
                                ? "Full Tests"
                                : "Full Test"
                            }}
                            /
                          </span>
                          {{ parseInt(plan.coin / 4) }}
                          {{
                            Number(plan.coin / 10) > 1 ? "Modules" : "Module"
                          }}
                          / {{ plan.coin / 1 }} Drills
                        </small>
                        <hr />
                        <div style="font-size:18px">
                          <b>
                            ${{ Math.round(plan.coin) }}
                            {{ plan.currency.toUpperCase() }}
                            <el-tooltip
                              class="item"
                              effect="dark"
                              content="Processing fee"
                              placement="top"
                            >
                              <el-tag
                                class="stripe-tag"
                                type="warning"
                                size="mini"
                                effect="dark"
                              >
                                +5%
                              </el-tag>
                            </el-tooltip>
                          </b>
                        </div>
                        <!-- <div
                          v-show="
                            parseInt(plan.price) / parseInt(plan.coin) < 1
                          "
                        >
                          <small class="line">
                            ${{ Math.round(plan.coin) }}
                            {{ plan.currency.toUpperCase() }}
                          </small>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div class="text-center" v-if="!useCoin && pay.count > 0">
              <hr />
              <h3 class="balance-price">{{ pay.count }}</h3>
              <span class="balance-name">{{ $t("sat.Coins") }}</span>
              <el-divider direction="vertical"></el-divider>
              <template>
                <h3 class="balance-price">${{ showUSD(pay.price) }}</h3>
                <span class="balance-name">USD</span>
              </template>
            </div>
            <div class="mb-3 mt-2" v-if="pay.count > 0">
              <el-button
                style="width: 100%;"
                @click="getPaymentLink()"
                :disabled="!(pay.id > 0)"
                type="success"
                >{{ $t("toefl.Pay now") }}</el-button
              >
            </div>
          </template>
          <template
            v-if="paymentMethod === 'green' || paymentMethod === 'greenMark'"
          >
            <p class="info-tip">{{ $t("toefl.Amount") }}</p>
            <div class="trade-list-wrapper">
              <div class="row">
                <template v-if="false">
                  <div
                    class="col-sm-6 col-md-6"
                    v-for="(plan, index) in twdPlans"
                    :key="index"
                  >
                    <div
                      :class="
                        plan.selected
                          ? 'trade-wrapper selected'
                          : 'trade-wrapper'
                      "
                      @click="selectTwdPlan(index)"
                    >
                      <el-tag
                        class="tags"
                        type="warning"
                        effect="dark"
                        v-show="
                          (Number(plan.price) - 30) / (Number(plan.coin) * 30) <
                            1
                        "
                      >
                        {{
                          $t("sat.discount", {
                            num:
                              lang === "en"
                                ? 100 -
                                  ((Number(plan.price) - 30) /
                                    (Number(plan.coin) * 30)) *
                                    100
                                : ((Number(plan.price) - 30) /
                                    (Number(plan.coin) * 30)) *
                                  10
                          })
                        }}
                      </el-tag>
                      <div class="text-center">
                        <b class="text-success tcoin">{{ plan.coin }}</b>
                        <b class="bb-name">{{ $t("sat.Coins") }}</b
                        ><br />
                        <small style="font-size:12px">
                          =

                          <span v-if="Number(plan.coin) > 10">
                            {{ Number(plan.coin / 10) }}
                            {{
                              Number(plan.coin / 10) > 1
                                ? "Full Tests"
                                : "Full Test"
                            }}
                            /
                          </span>
                          {{ parseInt(plan.coin / 4) }}
                          {{
                            Number(plan.coin / 10) > 1 ? "Modules" : "Module"
                          }}
                          / {{ plan.coin / 1 }} Drills
                        </small>
                        <hr />
                        <div style="font-size:18px">
                          <b>
                            ${{ Number(parseInt(plan.price) - 30) }}
                            TWD
                            <el-tooltip
                              class="item"
                              effect="dark"
                              content="Processing fee"
                              placement="top"
                            >
                              <el-tag
                                class="stripe-tag"
                                type="warning"
                                size="mini"
                                effect="dark"
                              >
                                +$30
                              </el-tag>
                            </el-tooltip>
                          </b>
                        </div>
                        <div
                          v-show="
                            Number(parseInt(plan.price) - 30) /
                              (parseInt(plan.coin) * 30) <
                              1
                          "
                        >
                          <small class="line">
                            ${{ Math.round(plan.coin * 30) }}
                            TWD
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template>
                  <div
                    class="col-sm-6 col-md-6"
                    v-for="(plan, index) in twdPlans"
                    :key="index"
                  >
                    <div
                      :class="
                        plan.selected
                          ? 'trade-wrapper selected'
                          : 'trade-wrapper'
                      "
                      @click="selectTwdPlan(index)"
                    >
                      <div class="text-center">
                        <b class="text-success tcoin">{{ plan.coin }}</b>
                        <b class="bb-name">{{ $t("sat.Coins") }}</b
                        ><br />
                        <small style="font-size:12px">
                          =

                          <span v-if="Number(plan.coin) > 10">
                            {{ Number(plan.coin / 10) }}
                            {{
                              Number(plan.coin / 10) > 1
                                ? "Full Tests"
                                : "Full Test"
                            }}
                            /
                          </span>
                          {{ parseInt(plan.coin / 4) }}
                          {{
                            Number(plan.coin / 10) > 1 ? "Modules" : "Module"
                          }}
                          / {{ plan.coin / 1 }} Drills
                        </small>
                        <hr />
                        <div style="font-size:18px">
                          <b>
                            ${{ Math.round(plan.coin * 30) }}
                            TWD
                            <el-tooltip
                              class="item"
                              effect="dark"
                              content="Processing fee"
                              placement="top"
                            >
                              <el-tag
                                class="stripe-tag"
                                type="warning"
                                size="mini"
                                effect="dark"
                              >
                                +$30
                              </el-tag>
                            </el-tooltip>
                          </b>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div class="text-center" v-if="!useCoin && pay.count > 0">
              <hr />
              <h3 class="balance-price">{{ pay.count }}</h3>
              <span class="balance-name">{{ $t("sat.Coins") }}</span>
              <el-divider direction="vertical"></el-divider>
              <template>
                <h3 class="balance-price">${{ Math.round(pay.price) }}</h3>
                <span class="balance-name">TWD</span>
              </template>
            </div>
            <div class="mb-3 mt-2" v-if="pay.count > 0">
              <el-button
                style="width: 100%;"
                @click="getGreenPaymentLink()"
                :disabled="!(pay.id > 0)"
                type="success"
                >{{ $t("toefl.Pay now") }}</el-button
              >
            </div>
          </template>
        </div>
      </div>
    </div>
    <template v-if="useCoin && useCoin > 0">
      <template v-if="pay.id > 0">
        <p class="text-success text-right">
          <b>充值：{{ pay.count }} {{ $t("sat.Coins") }}</b>
        </p>
        <!-- <p class="text-danger text-right">
          支付：{{ useCoin }} {{ $t("sat.Coin") }}
        </p>
        <p class="text-success text-right" v-if="pay.count - useCoin > 0">
          <b>
            {{ $t("toefl.Balance") }}({{ balance }} {{ $t("sat.Coin") }})：+
            {{ pay.count - useCoin }} {{ $t("sat.Coin") }}</b
          >
        </p>
        <p class="text-danger text-right" v-else>
          <b>
            {{ $t("toefl.Balance") }}({{ balance }} {{ $t("sat.Coin") }})：{{
              pay.count - useCoin
            }}
            {{ $t("sat.Coin") }}</b
          >
        </p> -->
        <hr />
      </template>
      <h2 class="mb-3 text-right text-success">
        ${{ showUSD(pay.price) }} USD
      </h2>
      <div class="text-right mb-3">
        <el-button
          type="success"
          :disabled="!(pay.id > 0) || balance + pay.count - useCoin < 0"
        >
          {{ $t("toefl.Pay now") }}
        </el-button>
      </div>
    </template>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapGetters } from "vuex";
/*eslint-enable */
import Toefl from "@/apis/sat.js";
import IpGeo from "@/apis/ipGeo.js";
import Balance from "@/apis/balance.js";
import _ from "lodash";
import payGreen from "@/apis/payGreen";
import $ from "jquery";

export default {
  props: ["useCoin", "balance"],
  data() {
    return {
      trades: [
        {
          id: 18,
          title: "方案一",
          price: "5.00",
          price_currency: "USD",
          point: 10,
          created_at: "2022-08-01T05:49:29.000000Z",
          updated_at: "2022-10-12T10:21:53.000000Z",
          order: 1,
          original_price: "10.00",
          desc:
            "<li><b>1</b>次單科練習的分數，或</li>\n<li><b>3</b>次單篇練習的分數</li>",
          is_show: 1,
          stripe_usd_price: "5.25",
          stripe_checkout_link:
            "https://buy.stripe.com/test_00g3dI3jL0wa9ry6oJ",
          stripe_id: null,
          recurring: null,
          selected: true
        },
        {
          id: 19,
          title: "方案二",
          price: "20.00",
          price_currency: "USD",
          point: 50,
          created_at: "2022-08-01T05:49:29.000000Z",
          updated_at: "2022-10-12T10:21:54.000000Z",
          order: 2,
          original_price: "50.00",
          desc:
            "<li><b>2</b>完整模考的分數，或</li>\n<li><b>2</b>次單篇練習的分數+簡單評語</li>",
          is_show: 1,
          stripe_usd_price: "21",
          stripe_checkout_link:
            "https://buy.stripe.com/test_fZeaGaf2t3ImgU028u",
          stripe_id: null,
          recurring: null,
          selected: false
        },
        {
          id: 20,
          title: "方案三",
          price: "60.00",
          price_currency: "USD",
          point: 200,
          created_at: "2022-08-01T05:49:29.000000Z",
          updated_at: "2022-10-12T10:21:57.000000Z",
          order: 3,
          original_price: "200.00",
          desc:
            "<li><b>1</b>次完整模考的分數+詳細評語，或</li>\n<li><b>2</b>次單篇練習的分數+詳細評語</li>",
          is_show: 1,
          stripe_usd_price: "63",
          stripe_checkout_link:
            "https://buy.stripe.com/test_eVa5lQ3jL5Qu9ry00n",
          stripe_id: null,
          recurring: null,
          selected: false
        },
        {
          id: 21,
          title: "方案四",
          price: "100.00",
          price_currency: "USD",
          point: 500,
          created_at: "2022-08-01T05:49:29.000000Z",
          updated_at: "2022-10-12T10:21:59.000000Z",
          order: 4,
          original_price: "500.00",
          desc:
            "<li><b>2</b>次完整模考的分數+簡單評語，或</li>\n<li><b>4</b>次單篇練習的分數+詳細評語，或</li>\n<li><b>12</b>次單篇練習的分數+簡單評語</li>",
          is_show: 1,
          stripe_usd_price: "105",
          stripe_checkout_link:
            "https://buy.stripe.com/test_14k3dI8E50wa1Z6eVi",
          stripe_id: null,
          recurring: null,
          selected: false
        }
      ],
      plans: [],
      location: "TW",
      paymentMethod: "STRIPE",
      custom: {
        count: "",
        selected: false
      },
      curType: "TW",
      currency: {
        CN: {
          num: 2.5,
          name: "CNY"
        },
        TW: {
          num: 10,
          name: "TWD"
        }
      },
      pay: {
        id: 0,
        count: 0,
        price: 0,
        price_currency: "TWD"
      }
    };
  },
  computed: {
    lang() {
      return this.$store.getters["user/langShortValue"];
    },
    locationIsTW() {
      return true;
    },
    usdPlans() {
      let plans = _.filter(this.plans, function(plan) {
        return plan.currency === "usd";
      });
      return plans;
    },
    twdPlans() {
      let plans = _.filter(this.plans, function(plan) {
        return plan.currency === "twd";
      });
      return plans;
    },
    paymentMethods() {
      const stripe = {
        value: "STRIPE",
        label: this.$t("toefl.Stripe")
      };
      const green = {
        value: "green",
        label: this.$t("toefl.Mobile payment")
      };
      const greenMark = {
        value: "greenMark",
        label: this.$t("toefl.Store payment")
      };
      if (!this.locationIsTW) {
        return [stripe];
      }
      return [stripe, green, greenMark];
    },
    isLogin() {
      return this.$store.getters["user/isLogin"];
    },
    PREVIOUS_ORDER() {
      return "PREVIOUS_ORDER";
    }
  },
  watch: {},
  async mounted() {
    // const { iso_code } = await this.$store.dispatch("ipGeo/getGeoLocation");
    // this.location = iso_code;
    // this.getPrice();
    // const previousOrder = window.localStorage.getItem(this.PREVIOUS_ORDER);
    // window.localStorage.removeItem(this.PREVIOUS_ORDER);
    // if (previousOrder) {
    //   const previousOrderObject = JSON.parse(previousOrder);
    //   this.pay.id = previousOrderObject.package_id;
    //   this.paymentMethod = previousOrderObject.payment_method;
    //   this.createOrder();
    // }
    const res = await IpGeo.getGeoLocation();
    console.log(res);
    if (res.iso_code === "TW") {
      this.paymentMethod = "green";
      console.log(res);
    } else {
      this.paymentMethod = "STRIPE";
      console.log(res);
    }
    this.getPlans();
  },

  methods: {
    async getPlans() {
      const res = await Balance.getPlans({
        type: "one_time"
      });
      this.plans = res.plans;
    },
    async getPaymentLink() {
      const loading = this.$loading({
        lock: true,
        text: "正在跳轉支付，請稍後。",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)"
      });
      let plan_list = [{ plan_id: this.pay.id }];
      const res = await Balance.getPaymentLink({
        plan_list: plan_list
      });
      window.location.href = res.url;
      loading.close();
    },
    async getGreenPaymentLink() {
      const loading = this.$loading({
        lock: true,
        text: "正在跳轉支付，請稍後。",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)"
      });
      const res = await Balance.getGreenPaymentLink({
        plan_id: this.pay.id,
        payment_method: this.paymentMethod === "greenMark" ? "BARCODE" : null
      });
      document.body.innerHTML = res;
      this.$nextTick(() => {
        $("#pay-form").submit();
      });
      loading.close();
    },
    showUSD(price) {
      if (Number(price) % 1 === 0) {
        return Number(price);
      } else {
        return price;
      }
    },
    selectPlan(index) {
      this.usdPlans.forEach(plan => {
        plan["selected"] = false;
      });
      console.log(index);
      if (index === -1) {
        this.custom.selected = true;
      } else {
        this.custom.selected = false;
        this.custom.count = "";
        this.usdPlans[index].selected = true;
        this.pay.id = this.usdPlans[index].id;
        this.pay.count = this.usdPlans[index].coin;
        // this.pay.price = Number(this.usdPlans[index].price);
        this.pay.price = this.usdPlans[index].coin;
        this.pay.price_currency = this.usdPlans[index].currency;
        this.pay.stripe_usd_price = this.usdPlans[index].stripe_usd_price;
      }
    },
    selectTwdPlan(index) {
      this.twdPlans.forEach(plan => {
        plan["selected"] = false;
      });
      console.log(index);
      if (index === -1) {
        this.custom.selected = true;
      } else {
        this.custom.selected = false;
        this.custom.count = "";
        this.twdPlans[index].selected = true;
        this.pay.id = this.twdPlans[index].id;
        this.pay.count = this.twdPlans[index].coin;
        // this.pay.price = Number(this.twdPlans[index].price);
        this.pay.price = this.twdPlans[index].coin * 30;
        this.pay.price_currency = this.twdPlans[index].currency;
        this.pay.stripe_usd_price = this.twdPlans[index].stripe_usd_price;
      }
    },
    selectPrice(index) {
      this.trades.forEach(trade => {
        trade.selected = false;
      });
      if (index === -1) {
        this.custom.selected = true;
      } else {
        this.custom.selected = false;
        this.custom.count = "";
        this.trades[index].selected = true;
        this.pay.id = this.trades[index].id;
        this.pay.count = this.trades[index].point;
        this.pay.price = this.trades[index].price;
        this.pay.price_currency = this.trades[index].price_currency;
        this.pay.stripe_usd_price = this.trades[index].stripe_usd_price;
      }
    },
    setCustomPrice() {
      this.custom.count = this.custom.count.replace(/\D+/g, "");
      this.pay.count = this.custom.count;
      this.pay.price = this.custom.count * this.currency[this.curType].num;
    },
    setCustomBlur() {
      if (this.custom.count === "") {
        this.selectPrice(0);
      }
    },
    async getPrice() {
      const trades = await Toefl.openPackages();
      trades.packages.forEach(trade => {
        trade["selected"] = false;
      });
      this.trades = trades.packages;

      if (this.$route.query["t-coins"]) {
        const defaultIndex = trades.packages.findIndex(
          aPackage => aPackage.point >= this.$route.query["t-coins"]
        );
        this.selectPrice(defaultIndex);
      } else {
        this.selectPrice(0);
      }
    },
    async createOrder() {
      if (this.pay.id > 0) {
        const currentOrder = {
          package_id: this.pay.id,
          payment_method: this.paymentMethod
        };
        if (this.isLogin) {
          const orderUrl = await Toefl.createOrder(currentOrder);
          window.location.href = orderUrl;
        } else {
          window.localStorage.setItem(
            this.PREVIOUS_ORDER,
            JSON.stringify(currentOrder)
          );
          this.$router.push({ name: "Login" });
        }
      }
    },
    getStripePrice(price) {
      const handlingFee = 0.05;
      return Math.ceil(Number(price) + Number(price) * handlingFee);
    }
  }
};
</script>

<style scoped>
.b-info .balance-price.balance-USD-price {
  margin-left: 10px;
  color: #757575;
}
.b-info .balance-name.balance-USD-name {
  color: #757575;
}
.balance-card {
  margin-bottom: 20px;
}
.bb-intro-top {
  width: 100%;
  border-top: 1px solid #ccd0d7;
  border-right: 1px solid #ccd0d7;
  border-left: 1px solid #ccd0d7;
  border-bottom: 1px solid #e2e9ed;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  background: #eef2f4;
  padding: 12px 28px;
  min-height: 46px;
}
.b-info {
  overflow: hidden;
  background: #fff;
  border-left: 1px solid #ccd0d7;
  border-right: 1px solid #ccd0d7;
  border-bottom: 1px solid #ccd0d7;
  box-sizing: border-box;
  border-radius: 0 0 4px 4px;
  padding: 0 28px;
}
.b-info .info-tip {
  margin-top: 15px;
  margin-bottom: 13px;
  font-size: 18px;
  /* color: #99a2aa; */
  letter-spacing: 0;
}
.b-info .balance-price {
  display: inline-block;
  font-size: 50px;
  color: var(--themeColor);
}
.b-info .balance-name {
  display: inline-block;
  font-size: 16px;
  margin-left: 4px;
  color: var(--themeColor);
}
.b-info .button {
  /* width: 140px; */
  height: 45px;
  float: right;
}
.trade-wrapper > div {
  display: table;
  /* margin: 18px auto 0; */
  padding: 10px 20px;
  width: 100%;
}
.trade-wrapper .bb-name {
  margin-left: 5px;
  font-size: 18px;
}
.trade-wrapper > div > div {
  margin-top: 8px;
  font-size: 14px;
  color: #757575;
  line-height: 14px;
}
.trade-wrapper hr {
  margin: 10px 0;
}
.trade-wrapper.selected {
  border: 2px solid var(--themeColor);
  color: var(--themeColor);
  background: #effff6;
  box-shadow: 0 2px 12px 0 #effff6;
}

.trade-custom {
  width: 200px;
  margin-right: 0;
}
.trade-custom .tips {
  margin: 29px 0 0 18px;
  font-size: 14px;
  color: #999;
  letter-spacing: 0;
}
.trade-list-wrapper {
  margin-top: 20px;
}
.trade-wrapper {
  position: relative;
  width: 100%;
  /* height: 180px; */
  height: 150px;
  margin-right: 16px;
  margin-bottom: 30px;
  border: 1px solid #e7ecf1;
  border-radius: 4px;
  font-size: 24px;
  color: #222;
  letter-spacing: 0;
  line-height: 24px;
  float: left;
  cursor: pointer;
  padding: 20px 0 0 0;
}
.tags {
  position: absolute;
  left: -2px;
  top: -15px;
  height: 30px;
  padding: 0 15px;
  line-height: 28px;
  border-radius: 15px 0 15px 0;
  font-size: 18px;
  font-weight: 700;
}
.line {
  color: #999999;
  text-decoration: line-through;
}
.tcoin {
  font-size: 40px;
}
@media screen and (max-width: 400px) {
  .b-info .button {
    text-align: center;
    float: none;
    width: 100%;
    margin-bottom: 15px;
  }
  .b-info {
    padding: 0 15px;
  }
}
</style>
